import React from 'react';
import ReactDOM from 'react-dom/client';
import { Nudge } from '@strategies/nudge';
import { HenshuApp } from '@strategies/henshu-app';

import './index.scss';
import seed from './seed';
import App from './components/App';
import { firebaseConfig } from './config';

// @ts-ignore
window.location = "https://projects.sasaki.com/auroraparks";

/*
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <div className="root">
            <Nudge config={{ ...firebaseConfig, collection: 'survey2' }}>
                <HenshuApp config={firebaseConfig} seeds={seed} langs={Object.keys(seed)}>
                    <App />
                </HenshuApp>
            </Nudge>
        </div>
    </React.StrictMode>
);
 */
